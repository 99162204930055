import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import '../../common/css/index.css';

import { Invoice } from './Invoice/Invoice';
import { NotFound } from './NotFound';
import { InvoiceError } from './Invoice/InvoiceError';
import { Root } from './Root';
import { Contact } from './Contact/Contact';
import { Callback } from './Auth/Callback';
import { LoggedOut } from './Auth/LoggedOut';
import { Profile } from './Profile/Profile';
import { Quote } from './Quote/Quote';
import { Statements } from './Contact/Statements';
import { Quotes } from './Contact/Quotes';

const router = createBrowserRouter([
  {
    path: `/`,
    element: <Root />,
    children: [
      {
        path: ``,
        element: <Profile />,
      },
      {
        path: `i/:code`,
        element: <Invoice />,
        errorElement: <InvoiceError />,
      },
      {
        path: `c/:code`,
        element: <Contact />,
        errorElement: <InvoiceError />,
        children: [
          {
            path: ``,
            element: <Statements type={ `outstanding` } />,
          },
          {
            path: `paid`,
            element: <Statements type={ `paid` } />,
          },
          {
            path: `quotes`,
            element: <Quotes />,
          },
        ],
      },
      {
        path: `q/:code`,
        element: <Quote />,
      },
      {
        path: `logout`,
        element: <LoggedOut />,
      },
      {
        path: `callback`,
        element: <Callback />,
      },
      {
        path: `*`,
        element: <NotFound />,
      },
    ],
  },
]);

export function App() {
  return (
    <RouterProvider router={ router }/>
  );
}
