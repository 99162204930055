import { IStatementColumns, IStatementCurrencySection, IStatementLine, StatementColumns } from "shared";
import { When } from "react-if";
import { useMemo, useState } from "react";

import { LoadingOverlay } from "../../../../main/components/Molecules/LoadingOverlay";
import { HelpIcon } from "../../../../main/components/Molecules/HelpIcon";
import { DatePopover } from "../../../../common/Components/DatePopover";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { CardContent } from "../../../../common/Atoms/CardContent";
import { Card } from "../../../../common/Atoms/Card";
import { formatAmount } from "../../../../common/lib/helpers";

import { StatementHeading } from "./StatementHeading";
import { StatementLines } from "./StatementLines";

interface IStatementSectionProps {
  type: `outstanding` | `paid`;
  currencySection: IStatementCurrencySection;
  columnSettings: IStatementColumns;
  sectionCount: number;
  statementPeriod: {
    start?: string;
    end?: string;
  };
  onDownload?: (currency: string) => void;
  downloadText?: string;
  downloadTip?: string;
  loading?: boolean;
  children?: React.ReactNode;
  organisationTimezone: string;
  childContacts?: { id?: number, name?: string }[];
  onDownloadInvoice?: (line: IStatementLine) => void;
  downloadingInvoices?: Record<string, boolean>; // InvoiceId
  showSplitBalances?: boolean;
  selectedInvoiceIds: string[];
  setSelectedInvoiceIds: (ids: string[]) => void;
  showPayButton?: boolean;
}

export function StatementSection({
  type,
  currencySection: statement,
  sectionCount,
  statementPeriod,
  onDownload,
  downloadText = `Download`,
  downloadTip = `Download Statement as PDF`,
  loading,
  children,
  organisationTimezone,
  childContacts,
  onDownloadInvoice,
  showSplitBalances,
  columnSettings = [],
  downloadingInvoices,
  selectedInvoiceIds,
  setSelectedInvoiceIds,
  showPayButton,
}: IStatementSectionProps) {

  const [downloading, setDownloading] = useState(false);

  const hasPaymentLinks = statement.lines.some(line => !!line.paymentLink) && type === `outstanding`;

  async function downloadAsPDF() {
    if (onDownload) {
      setDownloading(true);

      await onDownload(statement.currency);

      setDownloading(false);
    }
  }

  function handleDownloadInvoice(line: IStatementLine) {
    if (onDownloadInvoice) {
      onDownloadInvoice(line);
    }
  }

  if (!statement) {
    return null;
  }

  let statementPeriodText = null;

  if (statementPeriod.start) {

    statementPeriodText = (
      <div className={ `flex` }>
        { `All items outstanding issued in ` }
        <DatePopover
          className={ `ml-1` }
          date={ statementPeriod.start }
          labelFormat={ `LLLL yyyy` }
          time={ `start` }
          monthOnly
          organisationTimezone={ organisationTimezone }
        />
      </div>
    );
  }
  else if (statementPeriod.end) {
    statementPeriodText = (
      <div className={ `flex` }>
        { `All items outstanding issued on or before ` }
        <DatePopover
          className={ `ml-1` }
          date={ statementPeriod.end }
          labelFormat={ `dd LLLL yyyy` }
          time={ `end` }
          organisationTimezone={ organisationTimezone }
        />
      </div>

    );
  }

  const colCount = columnSettings.length
    - (hasPaymentLinks ? 0 : 1);

  const childIds = childContacts && childContacts.length > 0 ? childContacts.map(c => c.id) : [];
  const parentStatementLines = type === `outstanding`
    ? statement.lines.filter(line => !childIds.includes(line.contactId))
    : statement.paidLines.filter(line => !childIds.includes(line.contactId));

  const buttons = useMemo(() => {
    const buttonArray = [];
    if (onDownload && type === `outstanding`) {
      buttonArray.push({
        buttonText: downloadText,
        tooltip: downloadTip,
        onClick: downloadAsPDF,
        loading: downloading,
      });
    }

    return buttonArray;
  }, [onDownload, downloading, downloadAsPDF, downloadText, downloadTip]);

  const title = useMemo(() => {
    if (type === `paid`) {
      return `Recently Paid Invoices`;
    }

    if (sectionCount > 1) {
      return `Outstanding Statement (${statement.currency})`;
    }

    return `Outstanding Statement`;
  }, [type, sectionCount, statement.currency]);

  // Overdue age doesnt make sense in Paid invoices so remove if needed
  if (type === `paid`) {
    columnSettings = columnSettings.filter(column => column.type !== StatementColumns.OverdueAge);
  }

  const mainContent = (
    <>
      <MultiButtonCardHeader
        title={ title }
        description={ statementPeriodText }
        buttons={ buttons }
      />
      <LoadingOverlay
        loading={ loading }
      >
        <CardContent>
          <div className={ `mt-8 flow-root` }>
            <div className={ `-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8` }>
              <div className={ `inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8` }>
                <table className={ `min-w-full divide-y divide-gray-300` }>
                  <StatementHeading
                    columns={ columnSettings }
                    showPayColumn={ hasPaymentLinks }
                    showPayButton={ showPayButton }
                  />

                  { /* Always first render the parent contact id's lines */ }
                  <StatementLines
                    lines={ parentStatementLines }
                    columnSettings={ columnSettings }
                    showPayColumn={ hasPaymentLinks }
                    currency={ statement.currency }
                    organisationTimezone={ organisationTimezone }
                    handleDownloadInvoice={ handleDownloadInvoice }
                    downloadingInvoices={ downloadingInvoices }
                    selectedInvoiceIds={ selectedInvoiceIds }
                    setSelectedInvoiceIds={ setSelectedInvoiceIds }
                    showPayButton={ showPayButton }
                  />

                  { /* If there's child contacts render each one's invoices, if any */ }
                  <When condition={ childContacts && childContacts.length > 0 }>
                    { childContacts.map(child => {
                      const childLines = type === `outstanding`
                        ? statement.lines.filter(line => line.contactId === child.id)
                        : statement.paidLines.filter(line => line.contactId === child.id);

                      { /* If there are no lines for this child, skip rendering */ }
                      if (childLines.length === 0) {
                        return null;
                      }

                      return (
                        <StatementLines
                          key={ child.id + `statement-lines-child` }
                          lines={ childLines }
                          columnSettings={ columnSettings }
                          showPayColumn={ hasPaymentLinks }
                          currency={ statement.currency }
                          organisationTimezone={ organisationTimezone }
                          handleDownloadInvoice={ handleDownloadInvoice }
                          downloadingInvoices={ downloadingInvoices }
                          childName={ child.name }
                          selectedInvoiceIds={ selectedInvoiceIds }
                          setSelectedInvoiceIds={ setSelectedInvoiceIds }
                          showPayButton={ showPayButton }
                        />
                      );
                    }) }
                  </When>
                  <When condition={ type === `outstanding` }>
                    <tfoot>
                      <tr>
                        <td
                          colSpan={ colCount }
                          className={ `whitespace-nowrap py-2 text-sm text-gray-800 font-semibold` }
                        >
                          <div className={ `flex` }>
                            { `Balance:` }
                            <HelpIcon
                              className={ `ml-0.5` }
                              tooltip={ `This may differ from the total outstanding due to credit notes.` }
                            />
                          </div>
                        </td>
                        <td
                          className={ `whitespace-nowrap px-2 py-2 text-sm text-gray-800 font-semibold` }
                        >
                          { formatAmount(statement.totalOutstandingInCents, statement.currency) }
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={ colCount }
                          className={ `whitespace-nowrap py-2 text-sm text-gray-800 font-semibold` }
                        >
                          { `Total Outstanding:` }
                        </td>
                        <td
                          className={ `whitespace-nowrap px-2 py-2 text-sm text-gray-800 font-semibold` }
                        >
                          { formatAmount(statement.totalOutstandingGrossInCents, statement.currency) }
                        </td>
                      </tr>

                      <When condition={ showSplitBalances }>
                        <tr>
                          <td
                            colSpan={ colCount }
                            className={ `whitespace-nowrap py-2 text-sm text-gray-800 font-semibold` }
                          >
                            { `Total Due Now:` }
                          </td>
                          <td
                            className={ `whitespace-nowrap px-2 py-2 text-sm text-gray-800 font-semibold` }
                          >
                            { formatAmount(statement.splitData.before.totalOutstandingGrossInCents, statement.currency) }
                          </td>
                        </tr>

                        <tr>
                          <td
                            colSpan={ colCount }
                            className={ `whitespace-nowrap py-2 text-sm text-gray-800 font-semibold` }
                          >
                            { `Total Not Yet Due:` }
                          </td>
                          <td
                            className={ `whitespace-nowrap px-2 py-2 text-sm text-gray-800 font-semibold` }
                          >
                            { formatAmount(statement.splitData.after.totalOutstandingGrossInCents, statement.currency) }
                          </td>
                        </tr>
                      </When>
                    </tfoot>
                  </When>
                </table>
              </div>
            </div>
          </div>
          { children }
        </CardContent>
      </LoadingOverlay>
    </>
  );

  return (
    <Card>
      { mainContent }
    </Card>
  );
}
