import { IStatementColumns, IStatementLine, StatementColumns, StatementLineType } from "shared";
import { When } from "react-if";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { DocumentArrowDownIcon } from "@heroicons/react/20/solid";

import { classNames } from "../../../../common/lib/classNames";
import { Badge } from "../../../../common/Atoms/Badge";
import { DatePopover } from "../../../../common/Components/DatePopover";
import { SmallSpinner } from "../../../../common/Atoms/SmallSpinner";
import { formatAmount } from "../../../../common/lib/helpers";
import { CheckBox } from "../../../../common/Atoms/CheckBox";

interface IStatementLineProps {
  line: IStatementLine;
  columnSettings: IStatementColumns;
  organisationTimezone: string;
  showPayColumn?: boolean;
  downloadingInvoices?: Record<string, boolean>;
  handleDownloadInvoice: (line: IStatementLine) => void;
  toggleSelect?: (line: IStatementLine) => void;
  selected: boolean;
  showPayButton?: boolean;
}

export function StatementLine({
  line,
  columnSettings = [],
  organisationTimezone,
  showPayColumn,
  downloadingInvoices,
  handleDownloadInvoice,
  toggleSelect,
  selected,
  showPayButton,
}: IStatementLineProps) {

  const renderedLines = columnSettings.map((column, index) => {
    const isFirst = index === 0;
    if (column.type === StatementColumns.Activity) {
      return (
        <td
          key={ `column-${index}-${column.type}` }
          className={
            classNames(
              `whitespace-nowrap text-sm text-gray-900 truncate max-w-[150px]`,
              isFirst ? `py-2 pl-4 pr-3 sm:pl-0` : `px-2 py-2`,
              (line.type === StatementLineType.Invoice || line.type === StatementLineType.PaidInvoice) && `underline`,
            )
          }>
          <When condition={ line.type === StatementLineType.Invoice || line.type === StatementLineType.PaidInvoice }>
            <Link
              to={ `/i/${line.hash}` }>
              <Tooltip title={ line.invoiceNumber }>
                <span>
                  { line.invoiceNumber }
                </span>
              </Tooltip>
            </Link>
          </When>
          <When condition={ line.type === StatementLineType.CreditNote }>
            <Tooltip title={ `Credit Note` }>
              <span className={ `text-orange-700 flex items-center` }>
                { line.invoiceNumber }

                <Badge
                  size={ `xs` }
                  color={ `orange` }
                  className={ `ml-1` }
                >
                  { `C` }
                </Badge>
              </span>
            </Tooltip>
          </When>

          <When condition={ line.type === StatementLineType.Prepayment }>
            <Tooltip title={ `Prepayment` }>
              <span className={ `text-blue-700 flex items-center` }>
                { line.invoiceNumber }

                <Badge
                  size={ `xs` }
                  color={ `blue` }
                  className={ `ml-1` }
                >
                  { `P` }
                </Badge>
              </span>
            </Tooltip>
          </When>

          <When condition={ line.type === StatementLineType.Overpayment }>
            <Tooltip title={ `Overpayment` }>
              <span className={ `text-green-700 flex items-center` }>
                { line.invoiceNumber }

                <Badge
                  size={ `xs` }
                  color={ `green` }
                  className={ `ml-1` }
                >
                  { `O` }
                </Badge>
              </span>
            </Tooltip>
          </When>
        </td>
      );
    }

    if (column.type === StatementColumns.Reference) {
      return (
        <td
          key={ `column-${index}-${column.type}` }
          className={
            classNames(
              `whitespace-nowrap text-sm text-gray-900 truncate max-w-[150px]`,
              isFirst ? `py-2 pl-4 pr-3 sm:pl-0` : `px-2 py-2`,
            )
          }>
          <Tooltip title={ line.invoiceReference }>
            <span>{ line.invoiceReference }</span>
          </Tooltip>
        </td>
      );
    }

    if (column.type === StatementColumns.Date) {
      return (
        <td
          key={ `column-${index}-${column.type}` }
          className={
            classNames(
              `whitespace-nowrap text-sm font-medium text-gray-900`,
              isFirst ? `py-2 pl-4 pr-3 sm:pl-0` : `px-2 py-2`,
            )
          }>
          <DatePopover
            date={ line.issueDateString }
            organisationTimezone={ organisationTimezone }
            labelFormat={ `dd LLL yyyy` }
          />
        </td>
      );
    }

    if (column.type === StatementColumns.DueDate) {
      return (
        <td
          key={ `column-${index}-${column.type}` }
          className={
            classNames(
              `whitespace-nowrap text-sm font-medium text-gray-900`,
              isFirst ? `py-2 pl-4 pr-3 sm:pl-0` : `px-2 py-2`,
            )
          }>
          <When condition={ !!line.invoiceDueDateString }>
            {
              () => (
                <>
                  <DatePopover
                    date={ line.invoiceDueDateString }
                    organisationTimezone={ organisationTimezone }
                    labelFormat={ `dd LLL yyyy` }
                    time={ `end` }
                  />
                </>
              )
            }
          </When>
        </td>
      );
    }

    if (column.type === StatementColumns.OverdueAge) {
      return (
        <td
          key={ `column-${index}-${column.type}` }
          className={
            classNames(
              `whitespace-nowrap text-sm text-gray-500`,
              isFirst ? `py-2 pl-4 pr-3 sm:pl-0` : `px-2 py-2`,
            )
          }>
          <When condition={ line.debtAgeInDays }>
            <span className={ classNames(
              `font-semibold`,
              line.debtAgeInDays > 0 && `text-orange-500`,
              line.debtAgeInDays > 30 && `text-red-500`,
            ) }
            >
              { line.debtAgeInDays }
            </span>
            { ` days` }
          </When>
        </td>
      );
    }

    if (column.type === StatementColumns.Amount) {
      return (
        <td
          key={ `column-${index}-${column.type}` }
          className={
            classNames(
              `whitespace-nowrap text-sm text-gray-500`,
              isFirst ? `py-2 pl-4 pr-3 sm:pl-0` : `px-2 py-2`,
              line.type === StatementLineType.CreditNote ? `text-orange-700`: ``,
            )
          }>
          { formatAmount(line.totalInCents, line.currency) }
        </td>
      );
    }

    if (column.type === StatementColumns.Payments) {
      return (
        <td
          key={ `column-${index}-${column.type}` }
          className={
            classNames(
              `whitespace-nowrap text-sm text-gray-500`,
              isFirst ? `py-2 pl-4 pr-3 sm:pl-0` : `px-2 py-2`,
              line.type === StatementLineType.CreditNote ? `text-orange-700`: ``,
            )
          }>
          { formatAmount(line.paymentsInCents, line.currency) }
        </td>
      );
    }

    if (column.type === StatementColumns.Balance) {
      return (
        <td
          key={ `column-${index}-${column.type}` }
          className={
            classNames(
              `whitespace-nowrap text-sm text-gray-500`,
              isFirst ? `py-2 pl-4 pr-3 sm:pl-0` : `px-2 py-2`,
              line.type === StatementLineType.CreditNote ? `text-orange-700`: ``,
            )
          }>
          { formatAmount(line.outstandingInCents, line.currency) }
        </td>
      );
    }

    if (column.type === StatementColumns.QBOPrivateNote) {
      return (
        <td
          key={ `column-${index}-${column.type}` }
          className={
            classNames(
              `whitespace-nowrap text-sm text-gray-500`,
              isFirst ? `py-2 pl-4 pr-3 sm:pl-0` : `px-2 py-2`,
            )
          }>
          { line.qboPrivateNote }
        </td>
      );
    }

    if (column.type === StatementColumns.PromptPaymentDiscountAmount) {
      return (
        <td
          key={ `column-${index}-${column.type}` }
          className={
            classNames(
              `whitespace-nowrap text-sm text-gray-500`,
              isFirst ? `py-2 pl-4 pr-3 sm:pl-0` : `px-2 py-2`,
            )
          }>
          {
            line.totalPaidniceDiscountInCents
              ? formatAmount(line.totalPaidniceDiscountInCents, line.currency)
              : ``
          }
        </td>
      );
    }

    if (column.type === StatementColumns.Tax) {
      return (
        <td
          key={ `column-${index}-${column.type}` }
          className={
            classNames(
              `whitespace-nowrap text-sm text-gray-500`,
              isFirst ? `py-2 pl-4 pr-3 sm:pl-0` : `px-2 py-2`,
            )
          }>
          { formatAmount(line.totalTaxInCents, line.currency) }
        </td>
      );
    }

    return null;
  });

  return (
    <tr key={ line.invoiceId }>
      {
        // The select column`
        showPayButton && (
          <td className={ `whitespace-nowrap px-2 py-2 text-sm` }>
            <CheckBox
              checked={ selected }
              onChange={ () => toggleSelect(line) }
            />
          </td>
        )
      }

      { renderedLines }

      {
        showPayColumn && (
          <td className={ `whitespace-nowrap px-2 py-2 text-sm text-blue-600` }>
            <When condition={ line.paymentLink }>
              <a
                href={ line.paymentLink }
                target={ `_blank` }
                rel={ `noopener noreferrer` }
                className={ `underline` }
              >
                { `Pay Now` }
              </a>
            </When>
          </td>
        )
      }

      { /* Download links*/ }
      <td className={ `whitespace-nowrap px-2 py-2 text-sm text-gray-500` }>
        <When condition={ line.type === StatementLineType.Invoice || line.type === StatementLineType.PaidInvoice }>
          <button
            className={ classNames(
              downloadingInvoices?.[line.invoiceId] ? `cursor-wait opacity-70` : `cursor-pointer`,
            ) }
            onClick={ () => handleDownloadInvoice(line) }

            disabled={ downloadingInvoices?.[line.invoiceId] }
          >
            <When condition={ downloadingInvoices?.[line.invoiceId] }>
              <SmallSpinner colored />
            </When>
            <When condition={ !downloadingInvoices?.[line.invoiceId] }>
              <Tooltip title={ `Download Invoice PDF` }>
                <DocumentArrowDownIcon className={ `h-5 w-5 text-blue-400` } />
              </Tooltip>
            </When>
          </button>
        </When>
      </td>
    </tr>
  );
}
