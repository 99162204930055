import { When } from "react-if";
import { IStatementColumns, IStatementLine } from "shared";

import { formatAmount } from "../../../../common/lib/helpers";

import { StatementLine } from "./StatementLine";

interface IStatementLinesProps {
  lines: IStatementLine[];
  childName?: string;
  columnSettings: IStatementColumns;
  showPayColumn?: boolean;
  currency: string;
  organisationTimezone: string;
  handleDownloadInvoice: (line: IStatementLine) => void;
  downloadingInvoices?: Record<string, boolean>;
  selectedInvoiceIds: string[];
  setSelectedInvoiceIds: (ids: string[]) => void;
  showPayButton?: boolean;
}

export function StatementLines({
  lines,
  childName,
  columnSettings,
  showPayColumn,
  currency,
  organisationTimezone,
  handleDownloadInvoice,
  downloadingInvoices,
  selectedInvoiceIds,
  setSelectedInvoiceIds,
  showPayButton,
}: IStatementLinesProps) {
  const subtotalOutstanding = lines.reduce((acc, line) => acc + line.outstandingInCents, 0);

  const colCount = columnSettings.length;

  function toggleSelect(line: IStatementLine) {
    if (selectedInvoiceIds.includes(line.invoiceId)) {
      setSelectedInvoiceIds(selectedInvoiceIds.filter(id => id !== line.invoiceId));
    }

    if (!selectedInvoiceIds.includes(line.invoiceId)) {
      setSelectedInvoiceIds([...selectedInvoiceIds, line.invoiceId]);
    }
  }

  return (
    <tbody className={ `divide-y divide-gray-200 bg-white` }>
      <When condition={ childName }>
        <tr className={ `whitespace-nowrap` }><td>&nbsp;</td></tr>
        <tr>
          <th
            colSpan={ colCount }
            className={ `whitespace-nowrap py-2 text-sm text-gray-800 font-semibold bg-gray-100` }
          >
            { childName }
          </th>
          <th
            colSpan={ 3 }
            className={ `whitespace-nowrap py-2 text-sm text-gray-800 font-semibold bg-gray-100` }
          >
            { `Subtotal: ` }
            { formatAmount(subtotalOutstanding, currency) }
          </th>
        </tr>
      </When>
      { lines.map(line => {
        return (
          <StatementLine
            key={ line.invoiceId + `statement-line` }
            line={ line }
            showPayColumn={ showPayColumn }
            organisationTimezone={ organisationTimezone }
            handleDownloadInvoice={ handleDownloadInvoice }
            columnSettings={ columnSettings }
            downloadingInvoices={ downloadingInvoices }
            toggleSelect={ toggleSelect }
            selected={ selectedInvoiceIds.includes(line.invoiceId) }
            showPayButton={ showPayButton }
          />
        );
      }) }
    </tbody>
  );
}
