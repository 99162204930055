// Takes custom column data and renders the statement heading.

import { IStatementColumns, langauge } from "shared";

import { classNames } from "../../../../common/lib/classNames";

interface IStatementHeadingProps {
  columns: IStatementColumns;
  showPayColumn?: boolean;
  showPayButton?: boolean;
}

export function StatementHeading({
  columns,
  showPayColumn,
  showPayButton,
}: IStatementHeadingProps) {
  const headings = columns.map((column, index) => {
    const isFirst = index === 0;

    return (
      <th
        key={ `column-${index}-${column.type}` }
        scope={ `col` }
        className={ classNames(
          `whitespace-nowrap text-left text-sm font-semibold text-gray-900`,
          isFirst ? `py-3.5 pl-4 pr-3 sm:pl-0` : `px-2 py-3.5`,
        ) }
      >
        { langauge.settings.statement.statementColumnSettings[column.type].label }
      </th>
    );
  });

  return (
    <thead>
      <tr>
        {
          // The select column
          showPayButton && (
            <th
              scope={ `col` }
              className={ `whitespace-nowrap px-2 py-3.5` }
            >
              { `` }
            </th>
          )
        }
        { headings }
        {
          showPayColumn && (
            <th
              scope={ `col` }
              className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
            >
              { `Pay` }
            </th>
          )
        }

        { /* Download links */ }
        <th
          scope={ `col` }
          className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
        />

      </tr>
    </thead>
  );
}
