import { IPortalContactResponse, QuoteStatus } from "shared";
import { useState, useMemo } from "react";
import { Outlet, useLocation, useParams } from "react-router";

import { Card } from "../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../common/Atoms/MultiButtonCardHeader";
import { env } from "../../../common/lib/env";
import { useApiRequest } from "../../../main/hooks/useApiRequest";
import { useFetchContactQuery } from "../../services/api/contact";
import { useSession } from "../../hooks/useSession";
import { ITab, Tabs } from "../../../common/Atoms/Tabs";

interface ContactPageProps {
  contactDetails: IPortalContactResponse;
}

export function ContactPage({ contactDetails }: ContactPageProps) {
  const request = useApiRequest();
  const [downloadingAllLoading, setDownloadingAllLoading] = useState(false);
  const { code } = useParams();
  const { pathname } = useLocation();
  const session = useSession();
  const { refetch } = useFetchContactQuery({ code, sessionId: session?.id }, { skip: !code || !session?.id });

  async function onDownloadAllInvoices() {
    try {
      setDownloadingAllLoading(true);

      const result = await request({
        method: `GET`,
        responseType: `blob`,
        url: `${env.basePortalApiUrl}contact/code/${contactDetails.hash}/download-all.pdf`,
      });

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement(`a`);
      link.href = url;
      link.setAttribute(`download`, `${contactDetails.statement.contact.name}-all-invoices.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
    catch (e) {
      console.error(e);
      // TODO: how to present error cleanly on portal
    }
    finally {
      setDownloadingAllLoading(false);

      // this may have updated the contact so reload
      refetch();
    }
  }

  const quotesTitle = contactDetails?.language?.quotes || `Quotes & Estimates`;

  // async function onClaim() {
  //   const accessToken = await getAccessTokenSilently();

  //   cleamRequest({
  //     code: contactDetails.hash,
  //     accessToken,
  //   });
  // }

  // const shouldShowClaimButton = (isAuthenticated); // TODO - And if not already claim

  // let logInButtons = shouldShowClaimButton ?
  //   [{
  //     buttonText: `Claim this account`,
  //     onClick: onClaim,
  //     loading: isClaimLoading,
  //   }] :
  //   [{
  //     buttonText: `Login to claim this account`,
  //     onClick: () => {},
  //     loading: false,
  //     disabled: true,
  //   }];

  // if (!env.loginEnabled) {
  //   logInButtons = [];
  // }

  const buttons = useMemo(() => {
    if (!contactDetails.statement || !contactDetails.statement.currencySections?.length) {
      return [];
    }

    const totalInvoiceCount = contactDetails.statement.currencySections.reduce((acc, curr) => acc + curr.lines.length, 0);

    if (totalInvoiceCount === 0 || totalInvoiceCount > 6) {
      // Temp hide button when more than 6 invoices
      return [];
    }

    if (pathname.includes(`quotes`)) {
      // Dont show the download invoices button on the quotes page
      return [];
    }

    return [
      {
        buttonText: `Download all invoices`,
        onClick: onDownloadAllInvoices,
        loading: downloadingAllLoading,
        disabled: contactDetails.statement.currencySections.length === 0,
      },
    ];
  }, [setDownloadingAllLoading, contactDetails, downloadingAllLoading, pathname]);

  const tabs: ITab[] = useMemo(() => {
    const result: ITab[] = [
      {
        name: `Outstanding Statement`,
        href: ``,
        badge: {
          text: contactDetails?.statement?.totalOutstandingInvoiceCount?.toString(),
          color: `gray`,
        },
      },
    ];

    if (contactDetails.showQuotes) {
      result.push({
        name: quotesTitle,
        href: `quotes`,
        badge: {
          text: contactDetails.quotes?.filter(q => q.status === QuoteStatus.SENT)?.length?.toString(),
          color: `gray`,
        },
      });
    }

    if (contactDetails.allowViewPaid) {
      result.push({
        name: `Paid Invoices`,
        href: `paid`,
        badge: {
          text: contactDetails.statement?.totalPaidInvoiceCount?.toString(),
          color: `gray`,
        },
      });
    }

    return result;
  }, [contactDetails, quotesTitle]);

  return (
    <div className={ `max-w-full sm:max-w-3xl md:max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto` }>
      <Card className={ `mb-4` }>
        <MultiButtonCardHeader
          title={ contactDetails.statement.contact.name }
          description={ `Your customer portal for ${contactDetails.senderDetail?.customerFacingCompanyName}` }
          noDivider
          buttons={ buttons }
        />
      </Card>

      <Tabs
        tabs={ tabs }
      />
      <Outlet />
    </div>
  );
}
